<template>
  <div class="d-flex flex-column justify-content-center col-12 mt-3">
    <div class="d-flex flex-row align-items-center col-12">
      <div class="d-flex flex-row justify-content-center col-4">
        <button @click="calendarModeClicked('Daily')"
                :class="`${this.monthly === 0  ? 'toggle-date-button-active' : 'toggle-date-button-inactive'}`">Daily
        </button>
        <button @click="calendarModeClicked('Monthly')"
                :class="`${this.monthly === 1  ? 'toggle-date-button-active' : 'toggle-date-button-inactive'}`">Monthly
        </button>
      </div>
      <div class="d-flex flex-row justify-content-around flex-wrap col-4">
        <input v-model="dateRangePicked" class="col-12 col-md-4 col-sm-5 col-xl-5 date-picker" placeholder="Select Date..."
               type="text"
               id="datepicker" autocomplete="off"/>
        <div @click="campaignDropdownClicked()" v-if="userData.Internal && productConfig.campaignIdTitle"
             class="d-flex flex-column col-12 col-md-4 col-sm-5 col-xl-5 justify-content-center campaign-container">
          <img class="dropdown-img" src="@/assets/dropdown-icon.png">
          <div v-if="loadingCampaignData" class="spinner-grow spinner" role="status" style="color: #EE5C36">
          </div>
          <div class="d-flex flex-row align-items-center campaign-header p-1 col-12">{{
              this.truncate(campaignTitle, 26)
            }}
          </div>
          <div v-if="campaignDropdownVisible" class="dropdown-container col-12">
            <div @click="campaignSelected(null, 'All Campaigns')" v-if="campaignTitle !== 'All Campaigns'"
                 class="d-flex flex-column justify-content-center dropdown-row">All Campaigns
            </div>
            <div @click="campaignSelected(campaign.CampaignId, campaign.CustomDescription)"
                 v-for="campaign in this.campaignList" :key="index"
                 class="d-flex flex-column justify-content-center dropdown-row">
              {{ this.truncate(campaign.CampaignId + ': ' + campaign.CustomDescription, 30) }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center col-4">
        <button @click="confirmDateRangePicked(dateRangePicked)"
                class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 m-1 confirm-button">Confirm
        </button>
      </div>
    </div>
    <div v-if="productConfig.weeklyPricePoint" class="d-flex flex-column align-items-center mt-2">
      <div class="d-flex flex-row justify-content-center col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3">
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-3">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio" :value="undefined" v-model="serviceId"/>
          <label>All</label>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-3">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.pricePointServiceIds[0]}`" v-model="serviceId"/>
          <label>Daily</label>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-3">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.pricePointServiceIds[1]}`" v-model="serviceId"/>
          <label>Weekly</label>
        </div>
        <div v-if="productConfig.pricePointServiceIds[2]"
             class="d-flex flex-row justify-content-center align-items-center p-1 col-3">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.pricePointServiceIds[2]}`" v-model="serviceId"/>
          <label>Monthly</label>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between container-fluid mt-2">
      <div class="d-flex flex-column revenue-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2">
        <div class="metric-container-header p-1">Gross Revenues</div>
        <div v-if="!productConfig.currencySymbol.includes('$')">
          <div class="metric-value-text p-1">{{
              `${this.productConfig.currencySymbol}` + new Intl.NumberFormat('en-US').format(Math.ceil(revenueMetricsData.TotalGrossRevenue))
            }}
          </div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(revenueMetricsData.TotalGrossRevenue , revenueMetricsData.TotalGrossRevenueHistoricDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(revenueMetricsData.TotalGrossRevenue , revenueMetricsData.TotalGrossRevenueHistoricDelta)) ? 'green-text' : 'red-text' }`">
              {{
                Helpers.deltaPercentage(revenueMetricsData.TotalGrossRevenue, revenueMetricsData.TotalGrossRevenueHistoricDelta)
              }}
            </div>
          </div>
        </div>
        <div v-if="productConfig.currencySymbol.includes('$')">
          <div class="metric-value-text p-1">{{
              `${this.productConfig.currencySymbol}` + new Intl.NumberFormat('en-US').format(revenueMetricsData.TotalGrossRevenueUSD)
            }}
          </div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(revenueMetricsData.TotalGrossRevenueUSD , revenueMetricsData.TotalGrossRevenueHistoricDeltaUSD))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(revenueMetricsData.TotalGrossRevenueUSD , revenueMetricsData.TotalGrossRevenueHistoricDeltaUSD)) ? 'green-text' : 'red-text' }`">
              {{
                Helpers.deltaPercentage(revenueMetricsData.TotalGrossRevenueUSD, revenueMetricsData.TotalGrossRevenueHistoricDeltaUSD)
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column revenue-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2">
        <div class="metric-container-header p-1">Highest {{ dailyWeeklyText }} Revenue</div>
        <div v-if="!productConfig.currencySymbol.includes('$')">
          <div class="metric-value-text p-1">{{
              `${this.productConfig.currencySymbol}` + new Intl.NumberFormat('en-US').format(Math.ceil(revenueMetricsData.HighestDailyRevenue))
            }}
          </div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(revenueMetricsData.HighestDailyRevenue , revenueMetricsData.HighestDailyRevenueHistoricDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(revenueMetricsData.HighestDailyRevenue , revenueMetricsData.HighestDailyRevenueHistoricDelta)) ? 'green-text' : 'red-text' }`">
              {{
                Helpers.deltaPercentage(revenueMetricsData.HighestDailyRevenue, revenueMetricsData.HighestDailyRevenueHistoricDelta)
              }}
            </div>
          </div>
        </div>
        <div v-if="productConfig.currencySymbol.includes('$')">
          <div class="metric-value-text p-1">{{
              `${this.productConfig.currencySymbol}` + new Intl.NumberFormat('en-US').format(revenueMetricsData.HighestDailyRevenueUSD)
            }}
          </div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(revenueMetricsData.HighestDailyRevenueUSD , revenueMetricsData.HighestDailyRevenueHistoricDeltaUSD))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(revenueMetricsData.HighestDailyRevenueUSD , revenueMetricsData.HighestDailyRevenueHistoricDeltaUSD)) ? 'green-text' : 'red-text' }`">
              {{
                Helpers.deltaPercentage(revenueMetricsData.HighestDailyRevenueUSD, revenueMetricsData.HighestDailyRevenueHistoricDeltaUSD)
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column revenue-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2">
        <div class="metric-container-header p-1">Average {{ dailyWeeklyText }} Revenue</div>
        <div v-if="!productConfig.currencySymbol.includes('$')">
          <div class="metric-value-text p-1">{{
              `${this.productConfig.currencySymbol}` + new Intl.NumberFormat('en-US').format(Math.ceil(revenueMetricsData.AverageDailyRevenue))
            }}
          </div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(revenueMetricsData.AverageDailyRevenue , revenueMetricsData.AverageDailyRevenueHistoricDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(revenueMetricsData.AverageDailyRevenue , revenueMetricsData.AverageDailyRevenueHistoricDelta)) ? 'green-text' : 'red-text' }`">
              {{
                Helpers.deltaPercentage(revenueMetricsData.AverageDailyRevenue, revenueMetricsData.AverageDailyRevenueHistoricDelta)
              }}
            </div>
          </div>
        </div>
        <div v-if="productConfig.currencySymbol.includes('$')">
          <div class="metric-value-text p-1">{{
              `${this.productConfig.currencySymbol}` + new Intl.NumberFormat('en-US').format(revenueMetricsData.AverageDailyRevenueUSD)
            }}
          </div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(revenueMetricsData.AverageDailyRevenueUSD , revenueMetricsData.AverageDailyRevenueHistoricDeltaUSD))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(revenueMetricsData.AverageDailyRevenueUSD , revenueMetricsData.AverageDailyRevenueHistoricDeltaUSD)) ? 'green-text' : 'red-text' }`">
              {{
                Helpers.deltaPercentage(revenueMetricsData.AverageDailyRevenueUSD, revenueMetricsData.AverageDailyRevenueHistoricDeltaUSD)
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column revenue-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2">
        <div class="metric-container-header p-1">Closing Subscriber Base</div>
        <div class="metric-value-text p-1">{{ formatNumber(revenueMetricsData.AllSubscriptions) }}</div>
        <div class="d-flex flex-row text-center align-items-center pb-1">
          <div class="col-2"><img class="col-6"
                                  :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(revenueMetricsData.AllSubscriptions , revenueMetricsData.AllSubscriptionsHistoricDelta))">
          </div>
          <div
              :class="`${Helpers.setTextColour(Helpers.deltaPercentage(revenueMetricsData.AllSubscriptions , revenueMetricsData.AllSubscriptionsHistoricDelta)) ? 'green-text' : 'red-text' }`">
            {{
              Helpers.deltaPercentage(revenueMetricsData.AllSubscriptions, revenueMetricsData.AllSubscriptionsHistoricDelta)
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center col-12 mt-2">
      <BarChart :revenueMetric="revenueMetric.DailyRevenues"></BarChart>
    </div>
    <div class="d-flex flex-column align-items-center mt-2 mb-2 col-12">
      <div class="col-11" style="overflow-x:auto;">
        <table class="table table-hover" id="tblToExcl">
          <thead>
          <tr>
            <th class="col-4" scope="col">Date</th>
            <th class="col-4">
              Users Billed
            </th>
            <th class="col-4">Billing Revenue</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(day, index) in dailyRevenueTable.slice().reverse()" :key=index>
            <td> {{ Helpers.dateConversion(day.Date.slice(0, 10,), monthly) }}</td>
            <td>
              {{
                new Intl.NumberFormat('en-US').format(day.UniqueBilled)
              }}
            </td>
            <td v-if="!productConfig.currencySymbol.includes('$')" class="d-flex flex-row">
              {{
                `${this.productConfig.currencySymbol}` + new Intl.NumberFormat('en-US').format(day.SuccessfullyBilledAmount)
              }}
            </td>
            <td v-if="productConfig.currencySymbol.includes('$')" class="d-flex flex-row">
              {{
                `${this.productConfig.currencySymbol}` + new Intl.NumberFormat('en-US').format(day.SuccessfullyBilledAmountUSD.toFixed(2))
              }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="d-flex flex-column justify-content-center export-button p-2 mb-3" @click="htmlTableToExcel()">
        Export to Excel
      </button>
    </div>
  </div>
</template>

<script>
import BarChart from "@/views/components/charts/BarChart";
import flatpickr from "flatpickr";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import moment from 'moment';
import DashboardService from "@/services/dashboardService";
import {mapMutations, mapState} from "vuex";
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  name: "ProductRevenue",
  inheritAttrs: false,
  props: ['revenueMetric'],
  components: {
    BarChart
  },
  data() {
    return {
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      dailyRevenueTable: [],
      revenueMetricsData: [],
      startingDatePicked: undefined,
      endDatePicked: undefined,
      serviceId: undefined,
      campaignList: [],
      campaignId: null,
      campaignDropdownVisible: false,
      campaignTitle: `All Campaigns`,
      loadingCampaignData: false,
      calendarButtonText: undefined,
      dailyCalendarMode: {
        mode: "range",
        locale: {
          firstDayOfWeek: 1
        }
      },
      monthlyCalendarMode: {
        mode: "range",
        plugins: [
          new monthSelectPlugin({
            shorthand: false,
            dateFormat: "m.y",
          })
        ]
      },
      monthly: 0,
      dailyWeeklyText: 'Daily'
    }
  },
  computed: {
    ...mapState(['startingDateCalendar', 'endingDateCalendar', 'productConfig', 'userData'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setTriggerNewChart', 'setBarChartData', 'setBarChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar']),
    truncate(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
    calendarModeClicked(buttonText) {
      if (buttonText === 'Monthly') {
        this.monthly = 1;
        flatpickr('#datepicker', this.monthlyCalendarMode);
      } else if (buttonText === 'Daily') {
        this.monthly = 0;
        flatpickr('#datepicker', this.dailyCalendarMode);
      }
    },
    formatNumber(number) {
      if (Number.isNaN(number) || number === null || number === undefined) {
        return 'N/A';
      }
      return new Intl.NumberFormat('en-US').format(number);
    },
    htmlTableToExcel() {
      let table_elt = document.getElementById("tblToExcl");
      let workbook = XLSX.utils.table_to_book(table_elt);
      XLSX.writeFile(workbook, `${this.productConfig.productTitle} Revenue Report.xlsb`);
    },
    toggleMonthDailyText() {
      this.dailyWeeklyText = this.monthly === 1 ? 'Monthly' : 'Daily';
    },
    async confirmDateRangePicked(dateRange) {
      this.toggleMonthDailyText();
      this.sliceDateRange(dateRange);
      await this.setNewRevenueMetrics();
      this.setTriggerNewChart(true);
      this.setBarChartData(this.updatedRevenueMetricsData.DailyRevenues);
    },
    sliceDateRange(dateRange) {
      if (this.monthly === 0) {
        this.startingDatePicked = dateRange.slice(0, 10);
        this.endDatePicked = dateRange.substr(-10);
        this.setStartingDateCalendar(this.startingDatePicked);
        this.setEndingDateCalendar(this.endDatePicked);
      }
      if (this.monthly === 1) {
        this.convertDates(dateRange.slice(0, 5), dateRange.substr(-5));
        this.setStartingDateCalendar(this.startingDatePicked);
        this.setEndingDateCalendar(this.endDatePicked);
      }
    },
    convertDates(startingDate, endDate) {
      const [startMonth, startYear] = startingDate.split('.').map(part => parseInt(part, 10));
      const [endMonth, endYear] = endDate.split('.').map(part => parseInt(part, 10));
      this.startingDatePicked = `20${startYear.toString().padStart(2, '0')}-${startMonth.toString().padStart(2, '0')}-01`;
      const lastDay = new Date(20 + endYear, endMonth, 0).getDate();
      this.endDatePicked = `20${endYear.toString().padStart(2, '0')}-${endMonth.toString().padStart(2, '0')}-${lastDay}`;
    },
    async setNewRevenueMetrics() {
      this.updatedRevenueMetricsData = (await DashboardService.getRevenueStats(`${this.startingDatePicked}`, `${this.endDatePicked}`, this.productConfig.productId, this.serviceId, this.campaignId, this.monthly)).data;
      this.dailyRevenueTable = this.updatedRevenueMetricsData.DailyRevenues;
      this.revenueMetricsData = this.updatedRevenueMetricsData;
    },
    setDefaultRevenueMetrics() {
      this.revenueMetricsData = this.revenueMetric;
      this.dailyRevenueTable = this.revenueMetric.DailyRevenues;
      this.serviceId = undefined;
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`;
      this.startingDatePicked = this.startingDateCalendar;
      this.endDatePicked = this.endingDateCalendar;
    },
    triggerUpdatedChart() {
      this.setTriggerNewChart(true);
      this.setBarChartData(this.updatedRevenueMetricsData.DailyRevenues);
      this.setBarChartToLoad(this.productConfig.revenueGraph);
    },
    campaignDropdownClicked() {
      this.campaignDropdownVisible = !this.campaignDropdownVisible;
    },
    async getCampaignsList(product) {
      this.campaignList = (await DashboardService.getCampaignsByProduct(product)).data[0];
      this.campaignList.sort((a, b) => a.CampaignId - b.CampaignId);
    },
    async checkDateRangeChanged() {
      if (this.startingDateCalendar !== undefined) {
        this.serviceId = undefined;
        this.setUpdatedDateRange();
        await this.setNewRevenueMetrics();
        this.triggerUpdatedChart();
      } else {
        this.setDefaultRevenueMetrics();
        this.setBarChartToLoad(this.productConfig.revenueGraph);
      }
    },
    async campaignSelected(campaignId, description) {
      this.loadingCampaignData = true;
      this.campaignId = campaignId;
      if (campaignId !== null) {
        this.campaignTitle = `${campaignId}: ${description}`;
      } else {
        this.campaignTitle = description;
      }
      await this.confirmDateRangePicked(this.dateRangePicked);
      this.loadingCampaignData = false;
    },
  },
  mounted() {
    flatpickr('#datepicker', this.dailyCalendarMode);
  },
  async beforeMount() {
    await this.checkDateRangeChanged();
    await this.getCampaignsList(this.productConfig.campaignIdTitle);
  }
}
</script>

<style scoped>
.confirm-button, .export-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.toggle-date-button-active, .toggle-date-button-inactive {
  width: 100px;
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.toggle-date-button-active {
  background-color: #EE5C36;
  color: #FFFFFF;
}

.toggle-date-button-inactive {
  background-color: transparent;
  color: #000000;
}

.revenue-metric-container {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.metric-container-header, .red-text, .green-text {
  font-size: 18px;
}

.metric-value-text {
  font-size: 30px
}

.red-text {
  color: #ff0000;
}

.green-text {
  color: #207520;
}

input {
  text-align: center;
}

.date-picker, .campaign-container {
  height: 40px;
}

.campaign-container {
  position: relative;
}

.spinner {
  position: absolute;
  right: -38px;
}

.dropdown-img {
  position: absolute;
  right: 8px;
  top: 15px;
  width: 16px;
}

.dropdown-container {
  position: absolute;
  z-index: 1;
  background-color: #fffdfd;
  top: 40px;
}

.dropdown-row {
  height: 40px;
  border: .8px solid black;
  padding-left: 4px;
  font-size: 12.5px;
}

.campaign-header {
  border: .8px solid black;
  height: 100%;
}

@media only screen and (max-width: 576px) {
  .metric-container-header, .red-text, .green-text {
    font-size: 12px;
  }

  .metric-value-text {
    font-size: 12px;
  }

  table {
    margin-top: 20px;
    font-size: 10.5px;
  }

  .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  .toggle-date-button-active, .toggle-date-button-inactive {
    width: 55px;
    font-size: 10px;
    height: 28px;
  }

  .export-button {
    height: 28px;
    font-size: 15px;
  }

  .revenue-metric-container {
    width: 24% !important;
  }

  input {
    font-size: 10.5px;
  }
}

@media only screen and (max-width: 380px) {
  .metric-container-header, .red-text, .green-text {
    font-size: 10.5px;
  }

  .date-picker, .campaign-header {
    height: 30px;
  }

  .confirm-button {
    width: 55px;
    font-size: 10px;
  }

  input {
    font-size: 10px;
  }

  .campaign-header, .dropdown-row {
    font-size: 10.5px;
  }
}

</style>
