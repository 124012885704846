import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class DashboardService {
    static async getRevenueStats(fromDate, toDate, productId, service, campaignId, monthly) {
        return await axios.post(`${environmentConfig.dashboardService}/get-revenue-stats`, {
                fromDate,
                toDate,
                productId,
                service,
                campaignId,
                monthly,
            },
        )
    }

    static async getSubscriptionStats(fromDate, toDate, productId, service, campaignId, monthly) {
        return await axios.post(`${environmentConfig.dashboardService}/get-subscription-stats`, {
                fromDate,
                toDate,
                productId,
                service,
                campaignId,
                monthly
            },
        )
    }

    static async getEngagementStats(fromDate, toDate, productId) {
        return await axios.post(`${environmentConfig.dashboardService}/get-engagement-stats`, {
                fromDate,
                toDate,
                productId
            },
        )
    }

    static async getQuizIncUssdWebStats(fromDate, toDate, productId, hyveService) {
        return await axios.post(`${environmentConfig.dashboardService}/get-quizinc-engagement-data`, {
                fromDate,
                toDate,
                productId,
                hyveService
            },
        )
    }

    static async getExternalEngagementData(fromDate, toDate, productId, service, monthly) {
        return await axios.post(`${environmentConfig.dashboardService}/get-external-engagement-data`, {
                fromDate,
                toDate,
                productId,
                service,
                monthly
            },
        )
    }

    static async getPredictorStats(fromDate, toDate, promo, free) {
        return await axios.post(`${environmentConfig.dashboardService}/get-predictor-stats`, {
                fromDate,
                toDate,
                promo,
                free,
            },
        )
    }

    static async getAwardsData(fromDate, toDate, partnerId, partnerName) {
        return await axios.post(`${environmentConfig.dashboardService}/get-awards-data`, {
                fromDate,
                toDate,
                partnerId,
                partnerName
            }
        )
    }

    static async noAwardingFlag() {
        const response = await axios.post(`${environmentConfig.dashboardService}/no-awarding-flag`
        )
        return response.data;
    }

    static async campaignTargetUsers(productId, serviceId) {
        return await axios.post(`${environmentConfig.dashboardService}/get-target-users`, {
                productId,
                service: serviceId,
            },
        )
    }

    static async campaignSubscribedUserBase(serviceId) {
        return await axios.post(`${environmentConfig.dashboardService}/get-subscription-user-base`, {
                serviceId
            },
        )
    }

    static async campaignTargetUsersVodacomFantasy(fromDate, game, channel, status) {
        return await axios.post(`${environmentConfig.dashboardService}/get-target-users-fantasy-vodacom`, {
                fromDate,
                game,
                channel,
                status,
            },
        )
    }

    static async sendBulkCampaignSmsVodacomFantasy(smsText, fromDate, game, channel, status) {
        return await axios.post(`${environmentConfig.dashboardService}/send-bulk-campaign-sms-fantasy-vodacom`, {
                smsText,
                fromDate,
                game,
                channel,
                status,
            },
        )
    }

    static async getUnlockMoreSubscriptionStats(fromDate, toDate, subscriptionServiceId) {
        return await axios.post(`${environmentConfig.dashboardService}/get-unlock-more-subscription-stats`, {
                fromDate,
                toDate,
                subscriptionServiceId
            },
        )
    }

    static async getUnlockMoreEngagementStats(fromDate, toDate, hourly) {
        return await axios.post(`${environmentConfig.dashboardService}/get-unlock-more-engagement-stats`, {
                fromDate,
                toDate,
                hourly
            },
        )
    }

    static async getGameToWinEngagementStats(fromDate, toDate, productId, gameId) {
        return await axios.post(`${environmentConfig.dashboardService}/get-game-to-win-engagement-stats`, {
                fromDate,
                toDate,
                productId,
                gameId
            },
        )
    }

    static async addCampaign(region, network, product, channelMechanism, marketingPartner, customDescription, createdBy) {
        const response = await axios.post(`${environmentConfig.dashboardService}/add-campaign`, {
            region: region,
            network: network,
            product: product,
            channelMechanism: channelMechanism,
            marketingPartnerProvider: marketingPartner,
            customDescription: customDescription,
            createdBy: createdBy,
        })
        return response.data[0]
    }

    static async getCampaignsByProduct(product) {
        return await axios.post(`${environmentConfig.dashboardService}/get-campaigns-by-filters`, {
            region: null,
            network: null,
            product: product,
            channelMechanism: null,
            marketingPartnerProvider: null,
            customDescription: null,
        })
    }

    static async getCampaignsByFilters(region, network, product, channelMechanism, marketingPartnerProvider, customDescription) {
        return await axios.post(`${environmentConfig.dashboardService}/get-campaigns-by-filters`, {
            region: region,
            network: network,
            product: product,
            channelMechanism: channelMechanism,
            marketingPartnerProvider: marketingPartnerProvider,
            customDescription: customDescription,
        })
    }

    static async getCampaignsByDropDowns() {
        const response = await axios.post(`${environmentConfig.dashboardService}/get-campaigns-by-drop-downs`)
        return response.data;
    }

    static async upsertCampaignMessage(campaignName, campaignMessage, productId, productName, freePlay, dayRange, statusName, userBase, scheduled, scheduledDate, sent, serviceId, acquisitionCampaign) {
        return await axios.post(`${environmentConfig.dashboardService}/upsert-campaign-message`, {
            campaignName: campaignName,
            campaignMessage: campaignMessage,
            productId: productId,
            productName: productName,
            freePlay: freePlay,
            dayRange: dayRange,
            statusName: statusName,
            userBase: userBase,
            scheduled: scheduled,
            scheduledDate: scheduledDate,
            sent: sent,
            serviceId: serviceId,
            acquisitionCampaign: acquisitionCampaign
        })
    }

    static async getCampaignMessages(productId) {
        const response = await axios.post(`${environmentConfig.dashboardService}/get-campaign-messages`, {
            productId: productId,
        })
        return response.data[0];
    }

    static async deleteCampaignMessage(campaignMessageId) {
        const response = await axios.post(`${environmentConfig.dashboardService}/delete-campaign-message`, {
            campaignMessageId: campaignMessageId,
        })
        return response.data;
    }
}
