<template>
  <div class="d-flex flex-column voucher-awards-container col-12">
    <div v-if="totalVoucherStats.length > 0" class="d-flex flex-row voucher-stats flex-wrap col-12">
      <div class="d-flex flex-row justify-content-center align-items-center col-6 col-sm-6 col-md-3">
        <div class="d-flex flex-column align-items-center stat-item col-6">
          <span class="stat-title">Allocated:</span>
          <span class="stat-value">{{ totalVoucherStats[0].Allocated }}</span>
        </div>
        <div class="d-flex flex-column align-items-center stat-item">
          <span class="stat-title">Allocated Value:</span>
          <span class="stat-value">{{ 'R' + new Intl.NumberFormat('en-US').format(totalVoucherStats[0].AllocatedValue) }}</span>
        </div>
      </div>
      <div class="d-flex flex-row col-3 justify-content-center align-items-center col-6 col-sm-6 col-md-3">
        <div class="d-flex flex-column align-items-center stat-item col-6">
          <span class="stat-title">Claimed:</span>
          <span class="stat-value">{{ totalVoucherStats[0].Claimed }}</span>
        </div>
        <div class="d-flex flex-column align-items-center stat-item">
          <span class="stat-title">Claimed Value:</span>
          <span class="stat-value">{{ 'R' + new Intl.NumberFormat('en-US').format(totalVoucherStats[0].ClaimedValue) }}</span>
        </div>
      </div>
      <div class="d-flex flex-row col-3 justify-content-center align-items-center col-6 col-sm-6 col-md-3">
        <div class="d-flex flex-column align-items-center stat-item col-6">
          <span class="stat-title">Expired:</span>
          <span class="stat-value">{{ totalVoucherStats[0].Expired }}</span>
        </div>
        <div class="d-flex flex-column align-items-center stat-item">
          <span class="stat-title">Expired Value:</span>
          <span class="stat-value">{{ 'R' + new Intl.NumberFormat('en-US').format(totalVoucherStats[0].ExpiredValue) }}</span>
        </div>
      </div>
      <div class="d-flex flex-row col-3 justify-content-center align-items-center col-6 col-sm-6 col-md-3">
        <div class="d-flex flex-column align-items-center stat-item col-6">
          <span class="stat-title">Unclaimed:</span>
          <span class="stat-value">{{ totalVoucherStats[0].Unclaimed }}</span>
        </div>
        <div class="d-flex flex-column align-items-center stat-item">
          <span class="stat-title">Unclaimed Value:</span>
          <span class="stat-value">{{ 'R' + new Intl.NumberFormat('en-US').format(totalVoucherStats[0].UnclaimedValue) }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center col-11 mt-3">
      <div class="col-5 col-sm-6 col-md-2">
        <input
            v-model="filterVoucherCode"
            @input="filterVouchers"
            placeholder="Enter Voucher Code"
            class="filter-input"
        />
      </div>
      <div class="col-5 col-sm-6 col-md-2">
        <button @click="exportBtnClicked" class="excel-btn">Export to Excel</button>
      </div>
    </div>
    <div class="d-flex flex-row col-11 mt-3">
      <div class="d-flex flex-column justify-content-center align-items-start row-header">Product</div>
      <div class="d-flex flex-column justify-content-center align-items-start row-header">Msisdn</div>
      <div class="d-flex flex-column justify-content-center align-items-start row-header">Voucher Code</div>
      <div class="d-flex flex-column justify-content-center align-items-center row-header col-1">Value</div>
      <div class="d-flex flex-column justify-content-center align-items-start row-header">Date Allocated</div>
      <div class="d-flex flex-column justify-content-center align-items-start row-header">Claimed</div>
      <div class="d-flex flex-column justify-content-center align-items-start row-header">Date Claimed</div>
      <div class="d-flex flex-column justify-content-center align-items-start row-header">Expired</div>
      <div class="d-flex flex-column justify-content-center align-items-start row-header">Expiration Date</div>
    </div>
    <div class="d-flex flex-column col-11 table-container mt-1">
      <div class="d-flex flex-row" v-for="(voucher, index) in filteredVoucherStats" :key="index">
        <div class="d-flex flex-column justify-content-center align-items-start table-column">{{ getProductName(voucher.ProductId) }}</div>
        <div class="d-flex flex-column justify-content-center align-items-start table-column">{{ voucher.Msisdn }}</div>
        <div class="d-flex flex-column justify-content-center align-items-start table-column">{{ voucher.VoucherCode }}</div>
        <div class="d-flex flex-column justify-content-center align-items-center table-column">{{ formatCurrency(voucher.Value) }}</div>
        <div class="d-flex flex-column justify-content-center align-items-start table-column">{{ formatDate(voucher.DateAllocated) }}</div>
        <div class="d-flex flex-column justify-content-center align-items-start table-column">{{ voucher.Claimed }}</div>
        <div class="d-flex flex-column justify-content-center align-items-start table-column">{{ formatDate(voucher.DateClaimed) }}</div>
        <div class="d-flex flex-column justify-content-center align-items-start table-column">{{ voucher.Expired }}</div>
        <div class="d-flex flex-column justify-content-center align-items-start table-column">{{ formatDate(voucher.ExpirationDate) }}</div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapMutations, mapState} from "vuex";
import CampaignHubService from "@/services/campaignHubService";
import moment from "moment";
import * as XLSX from "xlsx";

export default {
  name: "VoucherAwards",
  data() {
    return {
      totalVoucherStats: [],
      individualVoucherStats: [],
      filterVoucherCode: "",
    };
  },
  computed: {
    ...mapState(["userData"]),
    filteredVoucherStats() {
      if (!this.filterVoucherCode) {
        return this.individualVoucherStats;
      }
      return this.individualVoucherStats.filter((voucher) =>
          voucher.VoucherCode.toLowerCase().includes(this.filterVoucherCode.toLowerCase())
      );
    },
  },
  methods: {
    ...mapMutations(["setSidebarMenuVisible"]),
    getProductName(productId) {
      const productMap = {
        3: 'Stop the Clock',
        5: 'QuizInc',
        6: 'Unlock 3',
        21: 'Head 2 Head'
      };

      return productMap[productId] || 'Product not found';
    },
    formatDate(date) {
      return moment(date, moment.ISO_8601, true).isValid()
          ? moment(date).format("DD-MM-YYYY")
          : "Not claimed";
    },
    formatCurrency(value) {
      return "R" + new Intl.NumberFormat("en-US").format(value);
    },
    async getVoucherStats() {
      const response = await CampaignHubService.getVoucherStats();
      this.totalVoucherStats = response[0];
      this.individualVoucherStats = response[1];
    },
    checkUserAccessRights() {
      return this.userData.Derisk;
    },
    filterVouchers() {
      this.filteredVoucherStats;
    },
    exportBtnClicked() {
      const ws1 = XLSX.utils.json_to_sheet(this.totalVoucherStats);
      const ws2 = XLSX.utils.json_to_sheet(this.individualVoucherStats);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws1, "Total Voucher Stats");
      XLSX.utils.book_append_sheet(wb, ws2, "Individual Voucher Stats");

      XLSX.writeFile(wb, "Voucher_Stats.xlsx");
    },
  },
  async beforeMount() {
    const userHasAccess = this.checkUserAccessRights();
    if (userHasAccess) {
      this.setSidebarMenuVisible(false);
      await this.getVoucherStats();
    }
  },
};
</script>

<style scoped>
.voucher-awards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.voucher-stats {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  height: 100px;
}

.stat-item {
  font-size: 17px;
}

.stat-item:last-child {
  border-bottom: none;
}

.stat-title {
  font-weight: bold;
}

.stat-value {
  font-weight: normal;
}

.table-container {
  position: relative;
  top: 0;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
}

.table-container::-webkit-scrollbar {
  display: none;
}

.row-header {
  border-bottom: 1px solid gray;
  padding-left: 5px;
  width: 11%;
}

.table-column {
  padding-left: 5px;
  height: 40px;
  padding-left: 5px;
  border-bottom: 1px solid gray;
  width: 11%;
}

.filter-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 40px;
}

.excel-btn {
  border-radius: 4px;
  height: 35px;
  width: 60%;
}

@media only screen and (max-width: 400px) {
  .stat-item, .stat-title, .stat-value, .table-container, .row-header {
    font-size: 11px;
  }

  .excel-btn {
    width: 100%;
    font-size: 12px;
  }

  .filter-input {
    font-size: 12px;
  }

  .row-header, .table-column {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .row-header {
    width: 50px;
  }

  .table-column {
    width: 100%;
  }
}

</style>
