import {createRouter, createWebHistory} from 'vue-router'
import LoginPage from "@/views/pages/LoginPage";
import PenroseDashboard from "@/views/pages/PenroseDashboard";
import StopTheClock from "@/views/pages/products/vodacom/StopTheClock";
import QuizInc from "@/views/pages/products/vodacom/QuizInc";
import MiniApp from "@/views/pages/products/vodacom/MiniApp";
import MzansiGames from "@/views/pages/products/mtn/MzansiGames";
import PenroseAwards from "@/views/pages/products/penrose/PenroseAwards";
import FantasyFootball from "@/views/pages/products/vodacom/FantasyFootball";
import UnlockThree from "@/views/pages/products/vodacom/UnlockThree";
import FantasyCdl from "@/views/pages/products/mtn/FantasyCdl";
import FantasyCmr from "@/views/pages/products/mtn/FantasyCmr";
import FantasyNga from "@/views/pages/products/mtn/FantasyNga";
import FantasyEth from "@/views/pages/products/ethiotel/FantasyEth";
import GameToWin from "@/views/pages/products/mtn/GameToWin";
import QuizIncHeadToHead from "@/views/pages/products/vodacom/QuizIncHeadToHead";
import BeatTheJams from "@/views/pages/products/vodacom/BeatTheJams";
import FreePlayPredictor from "@/views/pages/products/vodacom/FreePlayPredictor";
import UnlockMore from "@/views/pages/products/mtn/UnlockMore";
import QuizIncAllAccessPass from "@/views/pages/products/vodacom/QuizIncAllAccessPass";
import CampaignIds from "@/views/pages/products/penrose/CampaignIds";
import FantasyAirtel from "@/views/pages/products/airtel/FantasyAirtel";
import FantasyZmb from "@/views/pages/products/mtn/FantasyZmb";
import VodacomMzansiGames from "@/views/pages/products/vodacom/VodacomMzansiGames";
import MasterStream from "@/views/pages/products/vodacom/MasterStream";
import VoucherAwards from "@/views/pages/products/derisk/VoucherAwards";
import MzansiRhythm from "@/views/pages/products/mtn/MzansiRhythm";
import AisFantasy from "@/views/pages/products/ais/AisFantasy";
import AisMegamatchRivals from "@/views/pages/products/ais/AisMegamatchRivals";
import RugbyZone from "@/views/pages/products/vodacom/RugbyZone";
import MotorsportZone from "@/views/pages/products/vodacom/MotorsportZone";
import CricketZone from "@/views/pages/products/vodacom/CricketZone";
import {userAuthenticate} from "@/functions/userAuthenticate";
import {setProductConfig} from "@/functions/setProductConfig";

const routes = [
    {
        path: '/',
        redirect: 'login',
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/dashboard',
        name: 'PenroseDashboard',
        component: PenroseDashboard,
        children: [
            {
                path: 'stop-the-clock',
                name: 'StopTheClock',
                component: StopTheClock,
            },
            {
                path: 'quiz-inc',
                name: 'QuizInc',
                component: QuizInc,
            },
            {
                path: 'quiz-inc-head-2-head',
                name: 'QuizIncHeadToHead',
                component: QuizIncHeadToHead,
            },
            {
                path: 'mini-app',
                name: 'MiniApp',
                component: MiniApp,
            },
            {
                path: 'mzansi-games',
                name: 'MzansiGames',
                component: MzansiGames,
            },
            {
                path: 'game-to-win',
                name: 'GameToWin',
                component: GameToWin,
            },
            {
                path: 'penrose-awards',
                name: 'PenroseAwards',
                component: PenroseAwards,
            },
            {
                path: 'unlock-three',
                name: 'UnlockThree',
                component: UnlockThree,
            },
            {
                path: 'vodacom-fantasy',
                name: 'FantasyFootball',
                component: FantasyFootball,
            },
            {
                path: 'mtn-nga-fantasy',
                name: 'FantasyNga',
                component: FantasyNga,
            },
            {
                path: 'mtn-cmr-fantasy',
                name: 'FantasyCmr',
                component: FantasyCmr,
            },
            {
                path: 'mtn-cdl-fantasy',
                name: 'FantasyCdl',
                component: FantasyCdl,
            },
            {
                path: 'mtn-zmb-fantasy',
                name: 'FantasyZmb',
                component: FantasyZmb,
            },
            {
                path: 'eth-et-fantasy',
                name: 'FantasyEth',
                component: FantasyEth,
            },
            {
                path: 'airtel-nga-fantasy',
                name: 'FantasyAirtel',
                component: FantasyAirtel,
            },
            {
                path: 'beat-the-jams',
                name: 'BeatTheJams',
                component: BeatTheJams,
            },
            {
                path: 'freeplay-predictor',
                name: 'FreePlayPredictor',
                component: FreePlayPredictor,
            },
            {
                path: 'unlock-more',
                name: 'UnlockMore',
                component: UnlockMore,
            },
            {
                path: 'quiz-inc-aap',
                name: 'QuizIncAllAccessPass',
                component: QuizIncAllAccessPass,
            },
            {
                path: 'campaign-ids',
                name: 'CampaignIds',
                component: CampaignIds,
            },
            {
                path: 'vc-mzansi-games',
                name: 'VodacomMzansiGames',
                component: VodacomMzansiGames,
            },
            {
                path: 'master-stream',
                name: 'MasterStream',
                component: MasterStream,
            },
            {
                path: 'voucher-awards',
                name: 'VoucherAwards',
                component: VoucherAwards,
            },
            {
                path: 'mzansi-rhythm',
                name: 'MzansiRhythm',
                component: MzansiRhythm,
            },
            {
                path: 'ais-fantasy',
                name: 'AisFantasy',
                component: AisFantasy,
            },
            {
                path: 'ais-mmr',
                name: 'AisMegamatchRivals',
                component: AisMegamatchRivals,
            },
            {
                path: 'rugby-zone',
                name: 'RugbyZone',
                component: RugbyZone,
            },
            {
                path: 'motorsport-zone',
                name: 'MotorsportZone',
                component: MotorsportZone,
            },
            {
                path: 'cricket-zone',
                name: 'CricketZone',
                component: CricketZone,
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
    setProductConfig(to)
})

export default router
