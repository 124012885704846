<template>
  <div class="d-flex flex-column align-items-center">
    <div class="row align-items-center justify-content-center text-center reporting-metrics-container col-12">
      <div class="col-sm-2">
        <button @click="reportingMetricButtonClicked('productRevenue', 0)"
                :class="`col-6 col-lg-8 col-md-10 col-sm-12 m-2 ${reportingButtonIndex === 0 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
          Revenue
        </button>
      </div>
      <div class="col-sm-2">
        <button @click="reportingMetricButtonClicked('productSubscription', 1)"
                :class="`col-6 col-lg-8 col-md-10 col-sm-12 m-2 ${reportingButtonIndex === 1 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
          Subscription
        </button>
      </div>
      <div class="col-sm-2">
        <button @click="reportingMetricButtonClicked('productEngagement', 2)"
                :class="`col-6 col-lg-8 col-md-10 col-sm-12 m-2 ${reportingButtonIndex === 2 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
          Engagement
        </button>
      </div>
      <div class="col-sm-2">
        <button @click="reportingMetricButtonClicked('predictorEngagement', 3)"
                :class="`col-6 col-lg-8 col-md-10 col-sm-12 m-2 ${reportingButtonIndex === 3 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
          Predictor
        </button>
      </div>
      <div v-if="userData.SMSRights" class="col-sm-2">
        <button @click="reportingMetricButtonClicked('smsCampaign', 4)"
                :class="`col-6 col-lg-7 col-md-10 col-sm-12 m-1 ${reportingButtonIndex === 4 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
          Campaign
        </button>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center col-12" v-if="this.reportingMetricToShow === 'productRevenue'">
      <ProductRevenue :revenueMetric="revenueMetricsData"></ProductRevenue>
    </div>
    <div class="d-flex flex-column align-items-center col-12"
         v-if="this.reportingMetricToShow === 'productSubscription'">
      <ProductSubscription :subscriptionMetric="subscriptionMetricsData"></ProductSubscription>
    </div>
    <div class="d-flex flex-column align-items-center col-12" v-if="this.reportingMetricToShow === 'productEngagement'">
      <ProductEngagement :engagementMetric="engagementMetricsData"></ProductEngagement>
    </div>
    <div class="d-flex flex-column align-items-center col-12"
         v-if="this.reportingMetricToShow === 'predictorEngagement'">
      <PredictorEngagement :predictorEngagement="predictorEngagementMetricsData"></PredictorEngagement>
    </div>
    <div class="d-flex flex-column align-items-center col-12"
         v-if="this.reportingMetricToShow === 'smsCampaign' && userData.SMSRights">
      <FantasyFootballCampaign></FantasyFootballCampaign>
    </div>
  </div>
</template>

<script>
import ProductRevenue from "@/views/components/metrics/ProductRevenue";
import ProductSubscription from "@/views/components/metrics/ProductSubscription";
import ProductEngagement from "@/views/components/metrics/ProductEngagement";
import PredictorEngagement from "@/views/components/metrics/PredictorEngagement";
import FantasyFootballCampaign from "@/views/components/smsCampaign/vodacom/FantasyFootballCampaign";
import {mapMutations, mapState} from "vuex";
import DashboardService from "@/services/dashboardService";
import moment from "moment";

export default {
  name: "FantasyFootball",
  components: {ProductEngagement, ProductRevenue, ProductSubscription, PredictorEngagement, FantasyFootballCampaign},
  data() {
    return {
      reportingMetricToShow: undefined,
      reportingButtonIndex: undefined,
      revenueMetricsData: [],
      subscriptionMetricsData: [],
      engagementMetricsData: [],
      todayDate: `${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      startingDate: `${moment().subtract(7, 'd').format('YYYY-MM-DD')}`,
      predictorEngagementMetricsData: [],
    }
  },
  computed: {
    ...mapState(['isLoading', 'productConfig', 'userData'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setReportingMetric', 'setSidebarMenuVisible']),
    async reportingMetricButtonClicked(metricType, index) {
      await this.loadProductData(metricType);
      this.reportingMetricToShow = metricType;
      this.reportingButtonIndex = index;
      this.setReportingMetric(this.reportingMetricToShow);
    },
    async getRevenueDataMetrics() {
      this.revenueMetricsData = (await DashboardService.getRevenueStats(`${this.startingDate}`, `${this.todayDate}`, this.productConfig.productId, null, null, 0)).data;
    },
    async getSubscriptionDataMetrics() {
      this.subscriptionMetricsData = (await DashboardService.getSubscriptionStats(`${this.startingDate}`, `${this.todayDate}`, this.productConfig.productId, null, undefined, 0)).data;
    },
    async getEngagementDataMetrics() {
      this.engagementMetricsData = (await DashboardService.getEngagementStats(`${this.startingDate}`, `${this.todayDate}`, this.productConfig.productId)).data;
    },
    async getPredictorEngagementDataMetrics() {
      this.predictorEngagementMetricsData = (await DashboardService.getPredictorStats(`${this.startingDate}`, `${this.todayDate}`, 0, 0)).data;
    },
    async loadProductData(metricType) {
      const metricFunctions = {
        'productRevenue': this.getRevenueDataMetrics,
        'productSubscription': this.getSubscriptionDataMetrics,
        'productEngagement': this.getEngagementDataMetrics,
        'predictorEngagement': this.getPredictorEngagementDataMetrics,
      };
      const selectedFunction = metricFunctions[metricType];
      if (selectedFunction) await selectedFunction.call(this);
    },
    setReportingMetricToShow() {
      this.reportingMetricToShow = this.productConfig.initialMetricToDisplay;
      this.setReportingMetric(this.reportingMetricToShow);
      this.reportingButtonIndex = 0;
    },
    checkUserAccessRights() {
      return (this.userData.Vodacom);
    }
  },
  async beforeMount() {
    const userHasAccess = this.checkUserAccessRights();
    if (userHasAccess) {
      this.setSidebarMenuVisible(false);
      await this.loadProductData('productRevenue');
      this.setReportingMetricToShow();
    } else {
      this.$router.push(`/login`);
    }
  },
  watch: {
    '$store.state.reportingMetric': function () {
      if (this.$store.state.reportingMetric === 'productRevenue') {
        this.reportingMetricToShow = this.productConfig.initialMetricToDisplay
        this.reportingButtonIndex = 0;
      }
    }
  }
}
</script>

<style scoped>
.reporting-metrics-container {
  background-color: #E6E6E6;
  border-radius: 16px;
}

.reporting-metrics-button-default, .reporting-metrics-button-active {
  border-radius: 20px;
  border: 0;
  height: 40px;
  font-weight: 700;
  font-size: 20px;
}

.reporting-metrics-button-active {
  background-color: #EE5C36;
  color: #FFFFFF;
}

.reporting-metrics-button-default {
  background-color: #FFFFFF;
  color: #000000;
}

@media only screen and (max-width: 576px) {
  .reporting-metrics-button-default, .reporting-metrics-button-active {
    height: 28px;
    font-size: 17px;
  }
}

</style>
