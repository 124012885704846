<template>
  <div class="d-flex flex-column col-12">
    <div class="d-flex flex-row col-12">
      <div class="d-flex flex-column new-campaign-container m-2">
        <div class="d-flex flex-row col-12 justify-content-center align-items-center mt-3">
          <img class="col-2" src="../../../../assets/products/campaign-ids-logo.png">
          <div class="fw-bold campaign-header">Campaigns</div>
        </div>
        <div class="col-12 m-1 mt-3">New Campaign:</div>
        <div class="d-flex flex-column col-12">
          <div class="d-flex flex-row add-campaign-header-container align-items-center mt-1">
            <img class="add-campaign-icon col-1" src="../../../../assets/add-campaign-icon.png">
            <div class="add-campaign-text">Add Campaign</div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column main-container m-2">
        <div class="d-flex flex-row tab-container">
          <div @click="toggleTabs('campaign')" :class="`d-flex flex-column align-items-center justify-content-center col-6 ${activeTab === 'campaign' ? 'tab-active' : 'tab-inactive'}`">Campaign
          </div>
          <div @click="toggleTabs('runs')" :class="`d-flex flex-column align-items-center justify-content-center col-6 ${activeTab === 'runs' ? 'tab-active' : 'tab-inactive'}`">Runs</div>
        </div>
        <div v-if="activeTab === 'runs'" class="d-flex flex-column campaign-container col-12">
          <div class="campaign-runs-text mt-3">
            Campaign runs for {{ this.productConfig.productTitle }}
          </div>
          <div class="col-12 m-4" v-if="campaignMessages.length === 0">
            No campaign runs present...
          </div>
          <div class="d-flex flex-column align-items-center col-12 mb-3" v-for="(campaign, index) in campaignMessages" :key=index>
            <div class="d-flex flex-row campaign-run-container justify-content-start mt-2">
              <div class="d-flex flex-column col-9 mt-2 mb-3">
                <div class="m-2">
                  Run for Campaign: {{ campaign.CampaignName }}
                </div>
                <hr class="col-5"/>
                <div class="run-message">{{ campaign.CampaignMessage }}</div>
              </div>
              <div class="d-flex flex-column justify-content-start mt-3">
                <div>Base: {{ new Intl.NumberFormat('en-US').format(campaign.UserBase) + ' Users' }}</div>
                <div>Scheduled Message: {{ campaign.Scheduled }}</div>
                <div v-if="campaign.Scheduled">Date: {{ formatScheduledDate(campaign.ScheduledDate) }}</div>
              </div>
            </div>
            <div v-if="campaign.Sent === true" class="d-flex flex-row col-11 align-items-center campaign-status-completed-container">Completed</div>
            <div v-if="!campaign.Sent" class="d-flex flex-row col-11 align-items-center campaign-status-pending-container">Pending</div>
          </div>
        </div>
        <div v-if="activeTab === 'campaign'" class="d-flex flex-column align-items-center campaign-container">
          <div class="character-count">{{ campaignMessage.length + '/160' }}</div>
          <div class="col-11 mt-3">
            <textarea v-model="campaignName" placeholder="Enter Campaign Name" class="campaign-name-input col-12"></textarea>
            <textarea v-model="campaignMessage" placeholder="Enter Campaign Message" class="campaign-message-input col-12 mt-2"></textarea>
            <div class="d-flex flex-column justify-content-center product-name-input col-12 fst-italic mt-2">{{ productConfig.productTitle }}</div>
          </div>
          <div class="d-flex flex-column align-items-center col-12">
            <div class="col-11 mt-3">
              <div class="d-flex flex-row col-2 schedule-container">
                <div class="d-flex flex-column align-items-center justify-content-center col-3" style="background-color: #EE5C36">
                  <img class="col-9" src="../../../../assets/schedule-icon.png">
                </div>
                <div class="d-flex flex-column align-items-center justify-content-center col-9">
                  <input v-model="dateScheduled" class="col-12 date-picker" placeholder="Not Scheduled"
                         type="text"
                         id="datepicker" autocomplete="off"/>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column col-11">
            <div class="col-3 mt-2">
              <div v-if="userBase" class="d-flex flex-row fst-italic mt-3 mb-3" style="color: #707070">
                You are messaging to {{ new Intl.NumberFormat('en-US').format(userBase) }} Users
              </div>
              <div v-if="!userBase" class="d-flex flex-row mt-3 mb-3">
                <div class="spinner-grow" role="status">
                </div>
                <div class="d-flex flex-column justify-content-center m-1">Loading userbase...</div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center col-12">
            <div class="col-11 mt-3 mb-3">
              <div class="d-flex flex-row col-3">
                <button @click="saveClicked()" class="col-5 save-btn">Save</button>
                <button @click="sendClicked()" class="col-5 send-btn">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboardService";
import {mapMutations, mapState} from "vuex";
import flatpickr from "flatpickr";
import moment from "moment";

export default {
  name: "FantasyFootballCampaign",
  computed: {
    ...mapState(['userData', 'productConfig']),
  },
  data() {
    return {
      activeTab: 'campaign',
      campaignName: undefined,
      campaignMessage: [],
      smsContainer: false,
      userBase: undefined,
      scheduled: false,
      campaignMessages: [],
      dateScheduled: undefined,
    }
  },
  methods: {
    ...mapMutations(['setReportingMetric']),
    async getTargetedUserBaseFantasy() {
      this.userBase = (await DashboardService.campaignTargetUsersVodacomFantasy(null, null, null, 'Subscribed')).data.recordset.length;
    },
    returnHomeClicked() {
      this.setReportingMetric(this.productConfig.initialMetricToDisplay);
    },
    toggleTabs(tab) {
      this.activeTab = tab;
    },
    async saveClicked() {
      this.campaignMessages = [];
      await DashboardService.upsertCampaignMessage(this.campaignName, this.campaignMessage, this.productConfig.productId, this.productConfig.productTitle, null, null, null, this.userBase, true, this.dateScheduled, false);
      this.campaignMessages = await DashboardService.getCampaignMessages(this.productConfig.productId);
      this.toggleTabs('runs');
    },
    async sendClicked() {
      await DashboardService.upsertCampaignMessage(this.campaignName, this.campaignMessage, this.productConfig.productId, this.productConfig.productTitle, null, null, null, this.userBase, false, null, true);
      await DashboardService.sendBulkCampaignSmsVodacomFantasy(this.campaignMessage, null, null, null, 'Subscribed');
    },
    formatScheduledDate(date) {
      return moment(date).format('D MMMM YYYY, h:mm a');
    },
  },
  async beforeMount() {
    this.campaignMessages = await DashboardService.getCampaignMessages(this.productConfig.productId);
    await this.getTargetedUserBaseFantasy();
  },
  mounted() {
    flatpickr('#datepicker', {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      minDate: "today",
    })
  },
}
</script>

<style scoped>
square-selected, .square-default {
  border: 1px solid #aaaaaa;
  width: 13px;
  height: 13px;
  margin-right: 5px;
}

.product-name-input {
  padding-left: 15px;
}

.square-selected {
  background-color: #EE5C36;
}

.new-campaign-container {
  height: calc(100vh - 200px);
  width: 13%;
  border: 1px solid #bebebe;
}

.main-container {
  position: relative;
  width: 87%;
}

.campaign-header {
  font-size: 20px;
  margin-left: 8px;
}

.add-campaign-header-container {
  background-color: #EE5C36;
  color: #FFFFFF;
  height: 35px;
  margin-left: 3px;
  width: 96%;
}

.add-campaign-icon, .add-campaign-text {
  margin-left: 5px;
}

.tab-container {
  width: 20%;
}

.tab-inactive, .small-tab-inactive {
  border-right: 1px solid #bebebe;
  border-top: 1px solid #bebebe;
  border-left: 1px solid #bebebe;
}

.tab-active, .small-tab-active {
  background-color: #EE5C36;
  color: #FFFFFF;
}

.campaign-container {
  position: relative;
  border-left: 1px solid #bebebe;
  border-right: 1px solid #bebebe;
  border-top: 1px solid #bebebe;
  height: calc(100vh - 245px);
  border-bottom: 1px solid #bebebe;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.campaign-name-input, .product-name-input, .msisdn-list {
  height: 55px;
}

.campaign-message-input, .campaign-name-input, .product-name-input, .msisdn-list {
  border: 1px solid #bebebe;
}

.campaign-message-input {
  height: 70px;
}

textarea {
  padding-left: 20px;
  padding-top: 15px;
}

.character-count {
  position: absolute;
  right: 90px;
  top: 125px;
}

.small-tab-active, .small-tab-inactive, .tab-container, .save-btn, .send-btn {
  height: 45px;
}

.cohort-container, .sms-container {
  border: 1px solid #bebebe;
}

.radio-row {
  line-height: 1.1;
  font-size: 14px;
}

.schedule-container {
  height: 50px;
  border: 1px solid #bebebe;
}

.save-btn {
  border: 1px solid #bebebe;
  background-color: transparent;
}

.send-btn {
  border: 0;
  color: #FFFFFF;
  background-color: #EE5C36;
  margin-left: 14px;
}

.msisdn-container {
  margin-left: 10px;
}

.csv-container {
  margin-left: 14px;
  border: 1px solid #bebebe;
}

.campaign-runs-text {
  color: #9a9a9a;
  font-size: 18px;
  margin-left: 20px;
}

.campaign-run-container {
  height: 120px;
  border: 1px solid #bebebe;
}

.campaign-run-container, .campaign-status-completed-container, .campaign-status-pending-container {
  width: 95%;
}

.campaign-status-completed-container {
  background-color: #169416;
  color: #FFFFFF;
}

.campaign-status-pending-container, .campaign-status-completed-container {
  height: 26px;
  padding-left: 10px;
}

.campaign-status-pending-container {
  background-color: transparent;
  color: #9a9a9a;
  border-left: 1px solid #bebebe;
  border-right: 1px solid #bebebe;
  border-bottom: 1px solid #bebebe;
}

hr {
  height: 1px;
  border-top: 1px solid #000000;
  margin-left: 6px;
  margin-top: -8px;
}

.run-message {
  margin-left: 10px;
  margin-top: -10px;
  color: #9a9a9a;
}

.date-picker {
  height: 100%;
  border: 0;
  padding-left: 15px;
}
</style>
