<template>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column align-items-center reporting-metrics-container col-12 p-2">
      <div class="d-flex flex-row justify-content-center col-12">
        <div class="d-flex flex-column align-items-center justify-content-center m-2">
          <label>Choose Network:</label>
          <div class="d-flex flex-row justify-content-center col-12">
            <select @change="setNetworkAwardingData($event)" class="product-select-dropdown col-12 m-1">
              <option value="null">All</option>
              <option value="vodacom">Vodacom</option>
              <option value="mtn">MTN</option>
              <option value="telkom">Telkom</option>
              <option value="cell_c">Cell C</option>
              <option value="Penrose_Internal">Penrose</option>
            </select>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center m-1">
          <label v-if="showProductDropDown && partnerName">Choose Product:</label>
          <div class="d-flex flex-row justify-content-center col-12">
            <select v-if="partnerName === 'vodacom' && showProductDropDown" @change="setProductAwardingData($event)" class="product-select-dropdown col-12 m-1">
              <option value="null">All</option>
              <option v-if="vodacomArrayIncludes(3)" value="3">Fantasy Football</option>
              <option v-if="vodacomArrayIncludes(8)" value="8">Score Predictor Football</option>
              <option v-if="vodacomArrayIncludes(40)" value="40">Fantasy Cricket Zone</option>
              <option v-if="vodacomArrayIncludes(43)" value="43">Score Predictor Cricket Zone</option>
              <option v-if="vodacomArrayIncludes(46)" value="46">Trivia Cricket Zone</option>
              <option v-if="vodacomArrayIncludes(41)" value="41">Fantasy Motorsport Zone</option>
              <option v-if="vodacomArrayIncludes(44)" value="44">Score Predictor Motorsport Zone</option>
              <option v-if="vodacomArrayIncludes(47)" value="47">Trivia Motorsport Zone</option>
              <option v-if="vodacomArrayIncludes(42)" value="42">Fantasy Rugby Zone</option>
              <option v-if="vodacomArrayIncludes(45)" value="45">Score Predictor Rugby Zone</option>
              <option v-if="vodacomArrayIncludes(48)" value="48">Trivia Motorsport Zone</option>
              <option v-if="vodacomArrayIncludes(22)" value="22">FreePlay Predictor</option>
              <option v-if="vodacomArrayIncludes(11)" value="11">Stop The Clock</option>
              <option v-if="vodacomArrayIncludes(15)" value="15">Stop The Clock Loyalty</option>
              <option v-if="vodacomArrayIncludes(4)" value="4">Mzansi Games</option>
              <option v-if="vodacomArrayIncludes(5)" value="5">QuizInc</option>
              <option v-if="vodacomArrayIncludes(16)" value="16">QuizInc Loyalty</option>
              <option v-if="vodacomArrayIncludes(13)" value="13">QuizInc Welcome Back</option>
              <option v-if="vodacomArrayIncludes(10)" value="10">Daily 5</option>
              <option v-if="vodacomArrayIncludes(36)" value="36">Daily 5 - 1st Time Winner</option>
              <option v-if="vodacomArrayIncludes(37)" value="37">Daily 5 - Instant Prize</option>
              <option v-if="vodacomArrayIncludes(12)" value="12">Unlock 3</option>
              <option v-if="vodacomArrayIncludes(14)" value="14">Unlock 3 Loyalty</option>
              <option v-if="vodacomArrayIncludes(20)" value="20">QuizInc Head-2-Head</option>
              <option v-if="vodacomArrayIncludes(19)" value="19">Beat The Jams</option>
            </select>
          </div>
          <div class="d-flex flex-row justify-content-center col-12">
            <select v-if="partnerName === 'mtn'  && showProductDropDown" @change="setProductAwardingData($event)" class="product-select-dropdown col-12 m-1">
              <option value="null">All</option>
              <option v-if="mtnArrayIncludes(1)" value="1">Mzansi Games</option>
              <option v-if="mtnArrayIncludes(21)" value="21">Lucky Mzansi Games</option>
              <option v-if="mtnArrayIncludes(49)" value="49">Mzansi Rhythm</option>
              <option v-if="mtnArrayIncludes(6)" value="6">MTN Zambia</option>
              <option v-if="mtnArrayIncludes(17)" value="17">MTN Supa Scorer</option>
              <option v-if="mtnArrayIncludes(23)" value="23">Unlock More</option>
              <option v-if="mtnArrayIncludes(39)" value="39">Game2Win - Consolidated</option>
              <option v-if="mtnArrayIncludes(18)" value="18">Game2Win - Spin & Win</option>
              <option v-if="mtnArrayIncludes(26)" value="26">Game2Win - AI Trivia</option>
              <option v-if="mtnArrayIncludes(27)" value="27">Game2Win - Math</option>
              <option v-if="mtnArrayIncludes(28)" value="28">Game2Win - Trivia</option>
              <option v-if="mtnArrayIncludes(29)" value="29">Game2Win - Hangman</option>
              <option v-if="mtnArrayIncludes(30)" value="30">Game2Win - Unscramble</option>
              <option v-if="mtnArrayIncludes(31)" value="31">Game2Win - Code Breaker</option>
              <option v-if="mtnArrayIncludes(33)" value="33">Game2Win - ACME Bank</option>
              <option v-if="mtnArrayIncludes(34)" value="34">Game2Win - Easter Egg</option>
              <option v-if="mtnArrayIncludes(35)" value="35">Game2Win - Xs & Os</option>
              <option v-if="mtnArrayIncludes(38)" value="38">Game2Win - Battle Ship</option>
            </select>
          </div>
          <div class="d-flex flex-row justify-content-center col-12">
            <select v-if="partnerName === 'telkom'  && showProductDropDown" @change="setProductAwardingData($event)" class="product-select-dropdown col-12 m-1">
              <option value="null">All</option>
              <option v-if="telkomArrayIncludes(7)" value="7">Woza Games</option>
            </select>
          </div>
          <div class="d-flex flex-row justify-content-center col-12">
            <select v-if="partnerName === 'cell_c'  && showProductDropDown" @change="setProductAwardingData($event)" class="product-select-dropdown col-12 m-1">
              <option value="null">All</option>
              <option v-if="cellCArrayIncludes(32)" value="32">Msansi Games</option>
            </select>
          </div>
          <div class="d-flex flex-row justify-content-center col-12">
            <select v-if="partnerName === 'Penrose_Internal'  && showProductDropDown" @change="setProductAwardingData($event)" class="product-select-dropdown col-12 m-1">
              <option value="null">All</option>
              <option value="2">Penronse Internal</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-around col-12 mt-3">
      <button class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 overview-button">Overview</button>
      <input v-model="dateRangePicked" class="col-4 col-md-4 col-sm-5 col-xl-3" placeholder="Select Date..." type="text" id="datepicker" autocomplete="off"/>
      <button @click="confirmDateRangePicked(dateRangePicked)" class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 confirm-button">Confirm</button>
    </div>
    <div class="d-flex flex-column align-items-center col-11">
      <div v-if="dailySpendArray === undefined" class="mt-3 text-center">
        No Awarding Data for this date range. please select a new date range
      </div>
      <div v-if="loadedChildData && productAwardsData" class="d-flex flex-row justify-content-between container-fluid mt-4">
        <div class="d-flex flex-column award-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-3">
          <div class="metric-container-header p-1">Total Spend</div>
          <div class="metric-value-text p-1">{{ 'R' + new Intl.NumberFormat('en-US').format(productAwardsData.TotalProductSpend) }}</div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(productAwardsData.TotalProductSpend , productAwardsData.TotalProductSpendDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(productAwardsData.TotalProductSpend , productAwardsData.TotalProductSpendDelta)) ? 'green-text' : 'red-text' }`">
              {{ Helpers.deltaPercentage(productAwardsData.TotalProductSpend, productAwardsData.TotalProductSpendDelta) }}
            </div>
          </div>
        </div>
        <div class="d-flex flex-column award-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-3">
          <div class="metric-container-header p-1">Average Daily Spend</div>
          <div class="metric-value-text p-1">{{ 'R' + new Intl.NumberFormat('en-US').format(productAwardsData.AverageDailySpend) }}</div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(productAwardsData.AverageDailySpend , productAwardsData.AverageDailySpendDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(productAwardsData.AverageDailySpend , productAwardsData.AverageDailySpendDelta)) ? 'green-text' : 'red-text' }`">
              {{ Helpers.deltaPercentage(productAwardsData.AverageDailySpend, productAwardsData.AverageDailySpendDelta) }}
            </div>
          </div>
        </div>
        <div class="d-flex flex-column award-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-3">
          <div class="metric-container-header p-1">Users Awarded</div>
          <div class="metric-value-text p-1">{{ new Intl.NumberFormat('en-US').format(productAwardsData.UsersAwarded) }}</div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(productAwardsData.UsersAwarded , productAwardsData.UsersAwardedDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(productAwardsData.UsersAwarded , productAwardsData.UsersAwardedDelta)) ? 'green-text' : 'red-text' }`">
              {{ Helpers.deltaPercentage(productAwardsData.UsersAwarded, productAwardsData.UsersAwardedDelta) }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="dailySpendArray !== undefined" class="d-flex flex-column align-items-center mt-4 mb-2">
        <LineChart v-if="loadedChildData" :awardsMetric="dailySpendArray"></LineChart>
      </div>
      <div v-if="dailySpendArray !== undefined" class="d-flex flex-column align-items-center mt-1 mb-2 col-10">
        <div class="col-12">
          <table class="table table-hover" id="tblToExcl">
            <thead>
            <tr>
              <th class="col-3">Product Name</th>
              <th class="col-2" scope="col">Date</th>
              <th class="col-2">Total Spend</th>
              <th class="col-2">Users Awarded</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(day, index) in dailySpendArray.slice().reverse()" :key=index>
              <td class="col-3">{{ this.replaceUnderscoresWithSpaces(day.ProductName) }}</td>
              <td class="col-3"> {{ Helpers.dateConversion(day.Date.slice(0, 10)) }}</td>
              <td class="col-3"> {{ 'R' + new Intl.NumberFormat('en-US').format(day.TotalSpend) }}</td>
              <td>{{ new Intl.NumberFormat('en-US').format(day.UsersAwarded) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button class="d-flex flex-column justify-content-center export-button p-2 mt-2" @click="htmlTableToExcel()">Export to Excel</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapMutations, mapState} from "vuex";
import DashboardService from "@/services/dashboardService";
import flatpickr from "flatpickr";
import * as XLSX from 'xlsx/xlsx.mjs';
import LineChart from "@/views/components/charts/LineChart";

export default {
  name: "PenroseAwards",
  props: ['awardsMetric'],
  components: {
    LineChart,
  },
  data() {
    return {
      loadedChildData: false,
      partnerId: null,
      partnerName: null,
      showProductDropDown: false,
      productAwardsData: [],
      dailySpendArray: [],
      endDatePicked: `${moment().format().substring(0, 10)}`,
      startingDatePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')}`,
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().format().substring(0, 10)}`,
    }
  },
  computed: {
    ...mapState(['productConfig', 'reportingMetric', 'startingDateCalendar', 'endingDateCalendar', 'userData'])
  },
  methods: {
    ...mapMutations(['setSidebarMenuVisible', 'setReportingMetric', 'setLineChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar', 'setTriggerNewChart', 'setLineChartData']),
    async setNetworkAwardingData(event) {
      this.partnerName = event.target.value
      this.showProductDropDown = true;
      this.checkPartnerName();
      await this.loadAwardingData();
      this.loadRequiredChart();
    },
    checkPartnerName() {
      this.partnerId = null;
      this.showProductDropDown = true;
      if (this.partnerName === 'null') this.partnerName = null;
      if (this.partnerName) this.partnerId = null;
    },
    htmlTableToExcel() {
      let table_elt = document.getElementById("tblToExcl");
      let workbook = XLSX.utils.table_to_book(table_elt);
      XLSX.writeFile(workbook, `Product Awarding Spend ${this.partnerName + this.partnerId} Report.xlsb`);
    },
    async setProductAwardingData(event) {
      this.partnerId = event.target.value;
      this.checkPartnerId();
      await this.loadAwardingData();
      this.loadRequiredChart();
    },
    checkPartnerId() {
      if (this.partnerId === 'null') this.partnerId = null;
    },
    async loadAwardingData() {
      this.productAwardsData = (await DashboardService.getAwardsData(`${this.startingDatePicked}`, `${this.endDatePicked}`, this.partnerId, this.partnerName)).data;
      this.dailySpendArray = this.productAwardsData.DailySpend;
      this.loadedChildData = true;
    },
    async confirmDateRangePicked(dateRange) {
      this.sliceDateRange(dateRange);
      await this.loadAwardingData();
      this.loadRequiredChart();
    },
    sliceDateRange(dateRange) {
      this.startingDatePicked = dateRange.slice(0, 10);
      this.endDatePicked = dateRange.substr(-10);
      this.setStartingDateCalendar(this.startingDatePicked);
      this.setEndingDateCalendar(this.endDatePicked);
    },
    checkDateRangeChanged() {
      if (this.startingDateCalendar) this.setUpdatedDateRange();
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`;
      this.startingDatePicked = this.startingDateCalendar;
      this.endDatePicked = this.endingDateCalendar;
    },
    setProductToShow() {
      this.setReportingMetric(this.productConfig.initialMetricToDisplay);
    },
    loadRequiredChart() {
      this.setTriggerNewChart(true);
      this.setLineChartData(this.productAwardsData.DailySpend);
      this.setLineChartToLoad(this.productConfig.awardsGraph);
    },
    async checkProductAwardData(partnerName, partnerIdArray, noAwardsDataPartnerIdArray) {
      for (const partnerId of partnerIdArray) {
        const {TotalProductSpend} = (await DashboardService.getAwardsData(this.startingDatePicked, this.endDatePicked, partnerId, partnerName)).data;
        if (TotalProductSpend === undefined) {
          noAwardsDataPartnerIdArray.push(partnerId);
        }
      }
    },
    async checkProductAwardDataVodacom() {
      const partnerName = 'vodacom';
      const vodacomPartnerIdArray = [3, 4, 5, 8, 10, 11, 12, 13, 14, 15, 16, 19, 20, 22, 36, 37, 40, 41, 42, 43, 44, 45, 46, 47, 48];
      this.noAwardsDataPartnerIdVodacomArray = [];

      await this.checkProductAwardData(partnerName, vodacomPartnerIdArray, this.noAwardsDataPartnerIdVodacomArray);
    },
    async checkProductAwardDataMtn() {
      const partnerName = 'mtn';
      const mtnPartnerIdArray = [1, 6, 17, 18, 21, 23, 26, 27, 28, 29, 30, 31, 33, 34, 35, 38, 39, 49];
      this.noAwardsDataPartnerIdMtnArray = [];

      await this.checkProductAwardData(partnerName, mtnPartnerIdArray, this.noAwardsDataPartnerIdMtnArray);
    },
    async checkProductAwardDataTelkom() {
      const partnerName = 'telkom';
      const telkomPartnerIdArray = [7];
      this.noAwardsDataPartnerIdTelkomArray = [];

      await this.checkProductAwardData(partnerName, telkomPartnerIdArray, this.noAwardsDataPartnerIdTelkomArray);
    },
    async checkProductAwardDataCellC() {
      const partnerName = 'cell_c';
      const cellCPartnerIdArray = [32];
      this.noAwardsDataPartnerIdCellCArray = [];

      await this.checkProductAwardData(partnerName, cellCPartnerIdArray, this.noAwardsDataPartnerIdCellCArray);
    },
    mtnArrayIncludes(number) {
      return !this.noAwardsDataPartnerIdMtnArray.includes(number);
    },
    vodacomArrayIncludes(number) {
      return !this.noAwardsDataPartnerIdVodacomArray.includes(number);
    },
    telkomArrayIncludes(number) {
      return !this.noAwardsDataPartnerIdVodacomArray.includes(number);
    },
    cellCArrayIncludes(number) {
      return !this.noAwardsDataPartnerIdVodacomArray.includes(number);
    },
    async checkNetworkProductData() {
      await this.checkProductAwardDataVodacom();
      await this.checkProductAwardDataMtn();
      await this.checkProductAwardDataTelkom();
      await this.checkProductAwardDataCellC();
    },
    replaceUnderscoresWithSpaces(inputString) {
      if (typeof inputString !== 'string') {
        return inputString;
      }
      return inputString.replace(/_/g, ' ');
    },
    async checkNoProductAwarding() {
      const response = await DashboardService.noAwardingFlag();
      const productArray = [];
      await response.asyncForEach(async (product) => {
        productArray.push(`${product.partnerName.toUpperCase() + ' ' + product.product.toUpperCase()} `)
      });
      if (productArray.length > 0) alert(`Whoop whoop this is the no awarding alert police: We have no awarding data for the last 4 days for ${productArray}`);
    },
    checkUserAccessRights() {
      return (this.userData.Internal);
    }
  },
  async beforeMount() {
    const userHasAccess = this.checkUserAccessRights();
    if (userHasAccess) {
      this.setSidebarMenuVisible(false)
      this.checkDateRangeChanged();
      await this.checkNoProductAwarding();
      this.setProductToShow();
      await this.checkNetworkProductData();
      await this.loadAwardingData();
    } else {
      this.$router.push(`/login`);
    }
  },
  mounted() {
    flatpickr('#datepicker', {
      mode: "range"
    })
  },
}
</script>

<style scoped>
.reporting-metrics-container {
  background-color: #E6E6E6;
  border-radius: 16px;
}

label {
  font-size: 20px;
}

.product-select-dropdown {
  height: 25px;
}

.overview-button, .confirm-button, .export-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input {
  text-align: center;
}

.award-metric-container {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.metric-container-header, .red-text, .green-text {
  font-size: 18px;
}

.metric-value-text {
  font-size: 30px
}

.red-text {
  color: #ff0000;
}

.green-text {
  color: #207520;
}

@media only screen and (max-width: 576px) {
  .metric-container-header, .red-text, .green-text {
    font-size: 12px;
  }

  label {
    font-size: 13px;
  }

  .metric-value-text {
    font-size: 12px;
  }

  table {
    margin-top: 20px;
    font-size: 10.5px;
  }

  .overview-button, .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  .award-metric-container {
    width: 24% !important;
  }

  input {
    font-size: 10.5px;
  }

  .export-button {
    height: 28px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 370px) {
  .metric-container-header, .red-text, .green-text {
    font-size: 11px;
  }
}

</style>
